import { Component } from '@angular/core';
import { DataService } from "../../service/data.service";
import { Utils } from "../../service/utils";
import { PopUpModel } from "../../models/popUpModel";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmBlockDialogComponent } from "./confirm-block-dialog/confirm-block-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { catchError, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { LoyaltyUserResponseModel } from "../../models/loyaltyUserResponseModel";

@Component({
  selector: 'app-blocked-users-list',
  templateUrl: './blocked-users-list.component.html',
  styleUrls: ['./blocked-users-list.component.css']
})
export class BlockedUsersListComponent {
  searchForm: FormGroup;
  blockedUsersBO: any;
  pageOffset: number = 0;
  pageLimit: number = 10;
  currentPage: number = 1;
  totalPages: number = 1;
  inputLimit: number = 10;
  displayStyle = "none";
  listOffset: number | null = null;
  listCurrentPage: number | null = null;
  searchString: string = "";
  loyaltyUsers: LoyaltyUserResponseModel[] = [];
  dialogRef: MatDialogRef<ConfirmBlockDialogComponent> | null = null;
  isDialogOpen: boolean = false;

  popUp: PopUpModel = {
    title: '',
    error: '',
    info: ''
  };

  constructor(
    private dataservice: DataService,
    private util: Utils,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    public dialog: MatDialog
  ) {
    this.searchForm = this.formBuilder.group({
      searchString: ['', [Validators.maxLength(10)]]
    });
  }

  onKeyPress(event: KeyboardEvent) {
    const inputValue = event.key;
    if (!this.isValidInput(inputValue)) {
      event.preventDefault();
    }
  }

  isValidInput(inputValue: string): boolean {
    const searchStringControl = this.searchForm.get('searchString');
    const currentSearchString = searchStringControl ? searchStringControl.value : '';
    return /^\d+$/.test(currentSearchString + inputValue) && currentSearchString.length < 10;
  }

  performSearch() {
    this.listOffset = this.pageOffset;
    this.listCurrentPage = this.currentPage;
    this.searchString = this.searchForm?.get('searchString')?.value;
    if (this.searchString.length === 10 || this.searchString.length === 0) {
      this.refreshData();
    }
  }

  ngOnInit(): void {
    this.getBlockedUsersList();
  }

  refreshData(): void {
    const previousScrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.resetPaginationValues(0, this.inputLimit)
    this.currentPage = 1;
    this.totalPages = 1;
    this.getBlockedUsersList();

    setTimeout(() => {
      window.scrollTo(0, previousScrollPosition);
    }, 0);
  }

  resetPaginationValues(pageOffset: number, pageLimit: number) {
    if (pageLimit == 0 || pageLimit == null)
      pageLimit = 1;
    this.pageOffset = pageOffset;
    this.pageLimit = pageLimit;
  }

  getBlockedUsersList() {
    this.util.showSpinner();
    this.dataservice.getBlockedUsersList(this.pageOffset, this.pageLimit, this.searchString).subscribe(
      (blockedUsersDTO:any) => {
        this.blockedUsersBO = blockedUsersDTO
        this.loyaltyUsers = this.blockedUsersBO.loyaltyUsers;
        if (this.totalPages == 1) {
          this.totalPages = this.blockedUsersBO.totalPages;
        }
        this.util.hideSpinner();
        console.log(this.loyaltyUsers.length)
      }
    )
  }

  previousPage() {
    if (this.currentPage > 1) {
      if (this.pageOffset > 0) {
        this.pageOffset -= 1;
        this.currentPage -= 1;
        this.getBlockedUsersList();
      }
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      if (this.blockedUsersBO?.loyaltyUsers?.length === this.pageLimit) {
        this.pageOffset += 1;
        this.currentPage += 1;
        this.getBlockedUsersList();
      }
    }
  }

  closePopup() {
    this.displayStyle = "none";
  }

  blockUser(phoneNumber: string, isEarn: boolean) {
    const userPhoneNumberList: string[] = phoneNumber.split(",");
    this.dataservice.blockLoyaltyUsers(userPhoneNumberList, isEarn).pipe(
      catchError(err => {
        console.log(err.statusText);
        const responseCode = err.error?.metaData?.responseCode;
        this.toastrService.error(`Users not Blocked. ${responseCode}`);
        return throwError(err);
      })
    ).subscribe(
      (response: any) => {
        this.toastrService.success(response.message);
      }
    );
  }

  unblockUser(phoneNumber: string, isEarn: boolean) {
    const userPhoneNumberList: string[] = phoneNumber.split(",");
    this.dataservice.unblockLoyaltyUsers(userPhoneNumberList, isEarn).pipe(
      catchError(err => {
        console.log(err.statusText);
        const responseCode = err.error?.metaData?.responseCode;
        this.toastrService.error(`Users not Unblocked. ${responseCode}`);
        return throwError(err);
      })
    ).subscribe(
      (response: any) => {
        this.toastrService.success(response.message);
      }
    );
  }

  openConfirmDialogEarn(user: LoyaltyUserResponseModel, heading: string, disclaimer: string, event: any) {
    this.isDialogOpen = true;
    this.dialogRef = this.dialog.open(ConfirmBlockDialogComponent, {
      panelClass: 'confirm-dialog-container',
      data: {
        heading: heading,
        disclaimer: disclaimer
      },
      disableClose: true
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      if (result) {
        user.blockedEarn = !user.blockedEarn;
        if (user.blockedEarn) {
          this.blockUser(user.phoneNumber, true)
          console.log('User confirmed for Blocking Earn');
        } else {
          this.unblockUser(user.phoneNumber, true)
          console.log('User confirmed for unBlocking Earn');
        }
        user.blockedBurn = user.blockedEarn;
        event.target.checked = user.blockedEarn;
      } else {
        console.log('Confirmation cancelled');
        event.target.checked = user.blockedEarn;
      }
      this.dialogRef = null;
    });
  }

  openConfirmDialogBurn(user: LoyaltyUserResponseModel, heading: string, disclaimer: string, event: any) {
    this.isDialogOpen = true;
    this.dialogRef = this.dialog.open(ConfirmBlockDialogComponent, {
      panelClass: 'confirm-dialog-container',
      data: {
        heading: heading,
        disclaimer: disclaimer
      },
      disableClose: true
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      if (result) {
        user.blockedBurn = !user.blockedBurn;
        if (user.blockedBurn) {
          this.blockUser(user.phoneNumber, false)
          console.log('User confirmed for Blocking Burn');
        } else {
          this.unblockUser(user.phoneNumber, false)
          console.log('User confirmed for unBlocking Burn');
        }
        event.target.checked = user.blockedEarn;
      } else {
        console.log('Confirmation cancelled');
        event.target.checked = user.blockedBurn;
      }
      this.dialogRef = null;
    });
  }

  onBlockedEarnChange(user: LoyaltyUserResponseModel, event: any) {
    if (this.isDialogOpen) {
      event.preventDefault();
      return;
    }
    if(!user.blockedEarn){
      this.openConfirmDialogEarn(user, 'Do you want to Block the user ' + user.phoneNumber + ' from Earning Bayer Coins?', 'Please note: Blocking users from Earning Bayer Coins will also Block them from Redeeming Bayer Coins', event);
    } else{
      this.openConfirmDialogEarn(user, 'Do you want to Unblock the User ' + user.phoneNumber + ' from Earning Bayer Coins?', 'Please note: Unblocking users from Earning Bayer Coins will also UnBlock them from Redeeming Bayer Coins', event);
    }
  }

  onBlockedBurnChange(user: LoyaltyUserResponseModel, event: any) {
    if (this.isDialogOpen) {
      event.preventDefault();
      return;
    }
    if(!user.blockedBurn){
      this.openConfirmDialogBurn(user, 'Do you want to Block the user ' + user.phoneNumber + ' from Redeeming Bayer Coins?', 'Please note: Blocking users from Redeeming Bayer Coins will not Block them for Earning Bayer Coins', event);
    } else{
      this.openConfirmDialogBurn(user, 'Do you want to Unblock the User ' + user.phoneNumber + ' from Redeeming Bayer Coins?', 'Please note: Unblocking users from Redeeming Bayer Coins will not UnBlock them from Earning Bayer Coins', event);
    }
  }

}
