<div class="container">
  <div class="search-container">
    <div class="search-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-5 mt-2">
            <div>
              <h2>Loyalty Users</h2>
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-2 mt-2 text-right">
            <div class="input-group">
              <form [formGroup]="searchForm">
                <input class="form-control search-input text-center" type="search" formControlName="searchString"
                       (keypress)="onKeyPress($event)" (input)="performSearch()" placeholder="Search Phone Number"
                       aria-label="Search">
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-container table-responsive">
    <table class="table table-hover custom-table equal-width-table">
      <thead>
      <tr class="table-dark">
        <th scope="col">Sl. no.</th>
        <th scope="col">Phone Number</th>
        <th scope="col">Blocked Earn</th>
        <th scope="col">Blocked Burn</th>
      </tr>
      </thead>
      <tbody class="table-group-divider table-divider-color">
      <tr *ngFor="let user of loyaltyUsers; let i = index">
        <td>{{ (pageOffset * pageLimit) + i + 1 }}</td>
        <td>{{ user.phoneNumber }}</td>
        <td>
          <input type="checkbox" [checked]="user.blockedEarn" (change)="onBlockedEarnChange(user, $event)" [disabled]="isDialogOpen">
        </td>
        <td>
          <input type="checkbox" [checked]="user.blockedBurn" (change)="onBlockedBurnChange(user, $event)" [disabled]="isDialogOpen">
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="pagination-container" *ngIf="loyaltyUsers && loyaltyUsers.length > 0">
    <div class="input-container">
      <div class="input-group">
        <label> Limit: </label>
        <input type="number" [(ngModel)]="inputLimit" (input)="refreshData()" class="form-control small-input" min="10" max="15"/>
      </div>
    </div>
    <div class="arrow-buttons">
      <div class="arrow-button" *ngIf="currentPage != 1" (click)="previousPage()">
        <i class="fas fa-arrow-circle-left"></i>
      </div>
      <div class="page-info">
        {{ currentPage }}/{{ totalPages }}
      </div>
      <div class="arrow-button" *ngIf="currentPage < totalPages" (click)="nextPage()">
        <i class="fas fa-arrow-circle-right"></i>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ popUp.title }}</h4>
      </div>
      <div class="modal-body">
        <p *ngIf="popUp.error">{{ popUp.error }}</p>
        <p *ngIf="popUp.info">{{ popUp.info }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
